<template>
  <div class="content-accounts-receivable-table-all">
    <v-data-table
        mobile-breakpoint="0"
        :headers="aHeaders"
        :disable-sort="true"
        :items="aTables"
        :loading="bLoadingTable"
        loading-text="Cargando datos..."
        :items-per-page="-1"
        :hide-default-footer="true"
        no-data-text="No hay información disponible para mostrar."
        :footer-props="{
          'items-per-page-text': 'Filas por página',
          'items-per-page-all-text': 'Todos',
        }"
        class="global-table table-accounts-receivable"
      >
        <template slot="progress">
          <v-progress-linear
            color="var(--primary-color-loading-table)"
            indeterminate
          ></v-progress-linear>
        </template>

        <template v-slot:footer>
            <footer-table-component-global :numPages="numPages" :totalItems="totalItems"
              @changeTable="getEmitAccountsPayable" />
          </template>
        <!-- <template v-slot:[`item.sFolio`]="{ item }">
          <span @click="setOrder(item)" class="text-information-link mb-0">
            <v-icon
              size="16"
              color="var(--primary-color-link)"
              class="text-icon-launch mb-1"
              >mdi-launch</v-icon
            >{{ item.sFolio }}</span
          >
        </template>
        <template v-slot:[`item.dInitialQuantity`]="{ item }">
          <span>{{ (item.dInitialQuantity) }} </span>
        </template>
        <template v-slot:[`item.dPayableQuantity`]="{ item }">
          <span class="txt-pop-SemiBold"
            > {{ (item.dPayableQuantity) }} </span
          >
        </template> -->

        <!-- <template v-slot:footer>
          <div class="content-total-amount-pay-all">
            <div class="content-txt-price-total-amount-pay-all">
              <div class="content-txt-total-amount-pay">
                <p class="txt-total-amount-pay">Cantidad total a pagar</p>
              </div>
              <div class="content-price-total-amount-pay">
                <p class="price-total-amount-pay"> {{item.dTotalToPay}} MXN</p>
              </div>
            </div>
          </div>
          <footer-table-component-global
            v-if="false"
            :numPages="numPages"
            :totalItems="totalItems"
            @changeTable="getEmitTransation"
          />
        </template> -->
      </v-data-table>
    <!-- <div
      v-for="(item, i) in aTables"
      :key="i"
      class="content-accounts-receivable-table"
    >
      <v-data-table
        mobile-breakpoint="0"
        :hide-default-header="i != 0"
        :headers="aHeaders"
        :disable-sort="true"
        :items="item.aTable"
        :loading="bLoadingTable"
        loading-text="Cargando datos..."
        :items-per-page="-1"
        :hide-default-footer="true"
        no-data-text="No hay información disponible para mostrar."
        :footer-props="{
          'items-per-page-text': 'Filas por página',
          'items-per-page-all-text': 'Todos',
        }"
        class="global-table table-accounts-receivable"
      >
        <template slot="progress">
          <v-progress-linear
            color="var(--primary-color-loading-table)"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.sFolio`]="{ item }">
          <span @click="setOrder(item)" class="text-information-link mb-0">
            <v-icon
              size="16"
              color="var(--primary-color-link)"
              class="text-icon-launch mb-1"
              >mdi-launch</v-icon
            >{{ item.sFolio }}</span
          >
        </template>
        <template v-slot:[`item.dInitialQuantity`]="{ item }">
          <span>{{ (item.dInitialQuantity) }} </span>
        </template>
        <template v-slot:[`item.dPayableQuantity`]="{ item }">
          <span class="txt-pop-SemiBold"
            > {{ (item.dPayableQuantity) }} </span
          >
        </template>

        <template v-slot:footer>
          <div class="content-total-amount-pay-all">
            <div class="content-txt-price-total-amount-pay-all">
              <div class="content-txt-total-amount-pay">
                <p class="txt-total-amount-pay">Cantidad total a pagar</p>
              </div>
              <div class="content-price-total-amount-pay">
                <p class="price-total-amount-pay"> {{item.dTotalToPay}} MXN</p>
              </div>
            </div>
          </div>
          <footer-table-component-global
            v-if="false"
            :numPages="numPages"
            :totalItems="totalItems"
            @changeTable="getEmitTransation"
          />
        </template>
      </v-data-table>
    </div>
    <div class="content-placeholder-multitable-global" v-if="(aTables.length === 0)">
      <span class="text-placeholder-multitable-global">No hay información disponible para mostrar.</span>
    </div>
    <footer-table-component-global
      :numPages="numPages"
      :totalItems="totalItems"
      @changeTable="getEmitAccountsPayable"
    /> -->
  </div>
</template>
<script>
export default {
  props: {
    aTables: Array,
    aHeaders:Array,
    bLoadingTable: Boolean,
    numPages: Number,
    totalItems: Number,
  },
  data() {
    return {
      iItemsPerPage: 5,
    };
  },
  methods: {
    setOrder(oItem) {
      this.$emit("setOrder", oItem);
    },
    getEmitAccountsPayable(obj) {
      this.$emit("getEmitAccountsPayable", obj);
    },
  },
};
</script>
<style scoped>
.content-accounts-receivable-table-all {
  background-color: var(--primary-color-background-table) !important;
  margin-bottom: 25px !important;
  
  border-radius: 15px;
}


.table-accounts-receivable {
  box-shadow: 0px 0px 0px 0px !important;
  background-color: transparent !important;
}

.content-total-amount-pay-all {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
  border-bottom: 1px solid var(--primary-color-divider);
}

.content-txt-price-total-amount-pay-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
  padding: 0px 20px;
  min-width: 450px;
  max-width: 450px;
  min-height: 40px;
  background-color: var(--primary-color-background-blue-transparent);
  border-radius: 15px;
}

.txt-total-amount-pay {
  color: var(--primary-color-text-white);
  font-family: "pop-SemiBold";
  font-size: 14px;
  margin-bottom: 0px;
}
.price-total-amount-pay {
  color: var(--primary-color-text-white);
  font-family: "pop-SemiBold";
  font-size: 14px;
  margin-bottom: 0px;
}
</style>

<style>
.header-table-number-account-receivable {
}
.body-table-number-account-receivable {
  min-width: 80px !important;
  width: 80px !important;
  max-width: 80px !important;
}

.body-table-folio-account-receivable {
  min-width: 180px !important;
  width: 180px !important;
  max-width: 180px !important;
}

.body-table-client-account-receivable {
  min-width: 100px !important;
}

.body-table-date-account-receivable {
  min-width: 220px !important;
  width: 220px !important;
  max-width: 220px !important;
}

.body-table-initial-quantity-account-receivable {
  min-width: 220px !important;
  width: 220px !important;
  max-width: 220px !important;
}

.header-table-payable-quantity-account-receivable {
  padding-right: 40px !important;
}
.body-table-payable-quantity-account-receivable {
  min-width: 220px !important;
  padding-right: 40px !important;

  width: 220px !important;
  max-width: 220px !important;
}


.body-table-name-account-payable{
}

.body-table-date-range-account-payable{
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
  
}
.body-table-variation-account-payable{
  width: 180px !important;
  max-width: 180px !important;
  min-width: 180px !important;
}


.body-table-payable-total-debit-account-payable{
  width: 180px !important;
  max-width: 180px !important;
  min-width: 180px !important;
}
</style>
