import { render, staticRenderFns } from "./table.vue?vue&type=template&id=f1287a2e&scoped=true&"
import script from "./table.vue?vue&type=script&lang=js&"
export * from "./table.vue?vue&type=script&lang=js&"
import style0 from "./table.vue?vue&type=style&index=0&id=f1287a2e&scoped=true&lang=css&"
import style1 from "./table.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1287a2e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VDataTable,VProgressLinear})
